header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  height: 100%;
  border-bottom: 3px solid black;
  background: rgb(103,89,122);
  background: radial-gradient(circle, rgba(103,89,122,1) 0%, rgba(157,119,208,1) 47%, rgba(103,89,122,1) 89%, rgba(103,89,122,1) 100%);
  color: white;
  font-family: Gloria;
  font-size: 24px;
}

h1 {
  grid-column: 2;
  margin-bottom: 0;
  margin-top: 0;
}

.favorites-button {
  grid-column: 1;
  margin-left: 7%;
}

.home-button {
  grid-column: 3;
  margin-right: 7%;
}

.favorites-button,
.home-button {
  width: 50%;
  height: 90%;
  box-shadow: 5px 5px black;
  background-color: #004346;
  color: #FFFFFF;
  font-family: Gloria;
  font-size: 1.5vw;
  pointer-events: auto;
}

.favorites-button:hover,
.home-button:hover {
  box-shadow: 6px 6px black
}

.favorites-link,
.home-link {
  pointer-events: none;
}
