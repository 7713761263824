.card-info {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 4;
}

.delete-button {
  grid-row: 2;
  grid-column: 2;
  width: 100%;
  height: 80%;
  font-family: Gloria;
  font-size: 1.2vw;
}

.project-card {
  display: grid;
  grid-template-rows: 3fr 1fr;
  grid-template-columns: 30% 40% 30%;
  width: 25vw;
  height: 30vh;
  margin-top: 2%;
  border: 3px solid black;
  background-color: lightsteelblue;
  box-shadow: 8px 8px black;
  font-size: 1.5vw;
  overflow-wrap: break-word;
}
