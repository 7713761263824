@font-face {
  font-family: 'Gloria';
  src: local('Gloria'), url(../fonts/GloriaHallelujah-Regular.ttf) format('truetype');
}

html {
  height: 100%;
  background-color: white;
}

form {
  font-family: Gloria;
}

button:hover {
  cursor: pointer;
}

.App {
  text-align: center;
}

.change-subject-button {
  box-shadow: 2px 2px black;
  font-family: Gloria;
  font-size: 1.2vw;
  background-color: #004346;
  color: #FFFFFF;
}

.page-description {
  margin-top: 5%
}

.persuasiveTopics {
  background-color: #FFFEAD;
}

.programming {
  background-color: lightsteelblue;
}

.random-project-button {
  height: 5em;
  width: 30vw;
  margin-top: 2%;
  margin-bottom: 2%;
  border: 3px solid black;
  border-radius: 3%;
  box-shadow: 10px 10px;
  font-family: Gloria;
  font-size: 2.4vw;
}

.random-project-button:hover {
  box-shadow: 12px 12px;
}



@media only screen and (max-width: 600px) {
  html {
    height: 100%;
    background-color: white
  }

  header {
    font-size: 4vw;
  }

  .change-subject-button {
    font-size: 3vw;
  }

  .project-area {
    width: 80%;
    height: auto;
    margin: 10%;
    margin-top: 2%;
    font-size: 3.8vw;
  }

  .project-card {
    width: 30vw;
    height: 35vw;
    margin-bottom: 1%;
    font-size: 2.8vw;
  }

  .random-project-button {
    height: 20vw;
    width: 50vw;
    margin-top: 5%;
    margin-bottom: 2%;
    border-radius: 3%;
    border: 3px solid black;
    box-shadow: 10px 10px;
    font-family: Gloria;
    font-size: 3.5vw;
  }
}
